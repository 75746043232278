<template>
<div>
    <slot name="superTitle"></slot>

    <div class="text-xs whitespace-nowrap">{{ dataRef.title }}</div>
    <n-popover
        v-if="bots.help[dataRef.name]"
        scrollable
        trigger="hover"
        class="max-w-[200px] max-h-[200px]"
        :placement="helpPosition">
        <template #trigger>
            <n-icon class="text-main cursor-pointer text-2xl ml-2">
                <QuestionCircle20Filled />
            </n-icon>
        </template>
        <span class="text-[10px]" v-html="bots.help[dataRef.name]"></span>
    </n-popover>

    <n-button-group
        class="ml-2"
        :class="labelPosition === 'left' ? '' : 'mt-4'">
        <template v-for="item in dataRef.items" :key="item.id">
            <n-button
                strong
                size="tiny"
                :color="~dataRef.values.indexOf(item.id) ? gl.mainColor : ''"
                @click="$emit('update:values', { name: dataRef.name, id: item.id, reset: false })" >
                <span :class="!dataRef.values.length ? 'text-white' : ''">{{ item.title }}</span>
            </n-button>
        </template>
        
        <n-button
            v-if="reset"
            strong
            size="tiny"
            :color="!dataRef.values.length ? gl.mainColor : ''"
            @click="$emit('update:values', { name: dataRef.name, id: null, reset: true })" >
            <span :class="!dataRef.values.length ? 'text-white' : ''">off</span>
        </n-button>
    </n-button-group>
</div>
</template>

<script>
// vue
import { computed } from 'vue';

// naive-ui
import {
    NIcon,
    NButton,
    NPopover,
    NButtonGroup } from 'naive-ui';

// store
import { useGl } from '@/store/gl';
import { useBots } from '@/store/bots';
import { useRefs } from '@/store/refs';
import { useTools } from '@store/tools';

// icons
import { QuestionCircle20Filled } from '@vicons/fluent';

export default {
    name: 'rb-checkbox-group-mobile',
    props: {
        dataRef: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        helpPosition: {
            type: String,
            default: 'right',
        },
        labelPosition: {
            type: String,
            default: 'top',
            validator: prop => ['left', 'top'].includes(prop),
        },
        reset: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        NIcon,
        NButton,
        NPopover,
        NButtonGroup,
        QuestionCircle20Filled,
    },
    setup(props) {
    // store
        const gl = useGl();
        const bots = useBots();
        const refs = useRefs();
        const tools = useTools();

        return {
            gl,
            bots,
        };
    },
};
</script>

  