<template>
<section class="text-left">
    <div class="flex justify-between items-center">
        <div class="text-xs text-gray-600 dark:text-white/75 whitespace-nowrap">{{ bots.localization['algo_message'] }}</div>
            
        <n-button
            v-if="currPresetName !== 'default'"
            type="error"
            size="tiny"
            class="rounded-md text-white/90 bg-red-500 border-0 font-semibold"
            @click="presetChanged(null)">
            {{ t('resetSettings') }}
        </n-button>
    </div>
    <div class="flex flex-wrap mt-4">
        <div
            v-for="(preset, presetName) in bots.presets"
            :key="presetName"
            class="w-6/12 odd:pr-1 even:pl-1 mb-4">
            <n-button
                :secondary="presetName != currPresetName"
                size="medium"
                class="w-full rounded-md whitespace-normal"
                :class="presetName == currPresetName ? 'text-white/90' : ''"
                :color="presetName == currPresetName ? gl.mainColor : null"
                @click="presetChanged(presetName)">
                <div class="text-xs">{{ preset.title }}</div>
            </n-button>
            <div class="mt-2 text-[10px]">{{ preset.description }}</div>
        </div>
    </div>

    <router-link class="w-full mt-4" :to="{ name: 'tools.presets' }">
        <n-button
            secondary
            class="rounded-md font-semibold w-full">
            {{ bots.localization?.bot_template_management_label || 'Управление шаблонами' }}
        </n-button>
    </router-link>
</section>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NCard,
    NButton,
    NPopover } from 'naive-ui';

export default {
    name: 'bot-presets-mobile',
    components: {
        NCard,
        NButton,
        NPopover,
    },
    setup(props, context) {

        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.n-card__content) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>