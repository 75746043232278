import { ref, computed } from 'vue';

// store
import { useGl } from '@store/gl';
import { useBots } from '@store/bots';

export default function (props, context) {
    // store
    const gl = useGl();
    const bots = useBots();

    // vars
    const dataForm = bots.dataForm;
    const show = ref(false);

    const botRefsTagsOptions = computed(() => Object.values(bots.tags).map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const localValue = computed(() => {
        return dataForm.tags.map(({ id }) => id);
    });

    const updateValue = v => {
        dataForm.tags = [];
        
        v.forEach(el => {
            const item = botRefsTagsOptions.value.find(({ value }) => value == el);

            if (item)
                dataForm.tags.push({
                    title: item.title,
                    id: item.id,
                });
        });
    };

    return {
        gl,
        show,
        bots,
        dataForm,
        localValue,
        botRefsTagsOptions,
        updateValue,
    };
}