<template>
<div class="flex items-center cursor-pointer" @click="show = !show">
    <div class="text-md text-gray-600 dark:text-white/75 font-semibold">{{ bots.localization['bot_notifications_title'] }}</div>
    <n-icon class="ml-2 transition transform text-base" :class="show ? 'rotate-90' : ''">
        <IosArrowRtl24Filled />
    </n-icon>
</div>
<n-collapse-transition :show="show">
    <div class="mt-4">
        <rb-checkbox
            :label="'- ' + bots.localization['bot_notifications_use_custom_button']"
            v-model:checked="dataForm.use_custom_notifications" />
    </div>
    
    <div v-if="dataForm.use_custom_notifications" class="mt-6">
        <template v-for="(notification, i) in dataForm.notifications" :key="i + notification.name">
            <div class="text-left">
                <div v-if="notification.name=='token_to_pay_for_fees' && exchangeCommissionCoin" class="flex items-center">
                    <div class="text-xs whitespace-nowrap text-left text-gray-600 dark:text-white/75">{{ notification.title }}</div>
                    <n-popover
                        scrollable
                        trigger="hover"
                        class="max-w-[200px] max-h-[200px] rouned-md bg-main"
                        placement="top">
                        <template #trigger>
                            <n-icon class="text-main cursor-pointer text-xl ml-2">
                                <QuestionCircle20Filled />
                            </n-icon>
                        </template>
                        <span class="text-[10px] text-white" v-html="bots.help['token_to_pay_for_fees_balance_low']"></span>
                    </n-popover>
                </div>
                <div v-else class="flex items-center">
                    <div class="text-xs whitespace-nowrap text-left text-gray-600 dark:text-white/75">{{ notification.title }}</div>
                    <n-popover
                        v-if="bots.help[notification.name]"
                        scrollable
                        trigger="hover"
                        class="max-w-[200px] max-h-[200px] rouned-md bg-main"
                        placement="top">
                        <template #trigger>
                            <n-icon class="text-main cursor-pointer text-xl ml-2">
                                <QuestionCircle20Filled />
                            </n-icon>
                        </template>
                        <span class="text-[10px] text-white" v-html="bots.help[notification.name]"></span>
                    </n-popover>
                </div>
                <n-button-group class="mt-2">
                    <template v-for="(item, i) in notification.items" :key="i">
                        <n-button
                            strong
                            secondary
                            size="tiny"
                            class="first:rounded-l-md last:rounded-r-md flex items-center"
                            @click="updateNotificationsValues(dataForm.notifications, { name: notification.name, id: item.id, reset: false })" >
                            <n-icon size="20" v-if="~notification.values.indexOf(item.id)" class="text-main mr-1"><Checkmark12Filled /></n-icon>
                            <span :class="~notification.values.indexOf(item.id) ? 'text-main' : 'text-gray-600 dark:text-white/75'">{{ item.title }}</span>
                        </n-button>
                    </template>
                    
                    <n-button
                        strong
                        secondary
                        size="tiny"
                        type="error"
                        class="first:rounded-l-md last:rounded-r-md flex items-center"
                        @click="updateNotificationsValues(dataForm.notifications, { name: notification.name, id: null, reset: true })" >
                        <n-icon size="20" v-if="!notification.values.length" class="text-red-500 mr-1"><Checkmark12Filled /></n-icon>
                        <span class="text-red-500">off</span>
                    </n-button>
                </n-button-group>
            </div>
            <n-divider class="my-3" />
        </template>

        <div class="flex items-end">
            <rb-select
                multiple
                class="w-5/12"
                labelPosition="left"
                :defaultValue="false"
                :label="bots.localization['bot_notifications_select_email_f']"
                :options="emailOptions"
                v-model:value="dataForm.notifications_contacts.email" />
            <n-button
                strong
                class="ml-2 rounded-md text-white/90"
                :color="gl.mainColor"
                @click="addEmailModalShow = true" >
                <strong>+</strong>&nbsp;
                {{ bots.localization['bot_notifications_select_email_add_new_email_modal_add_email_button'] }}
            </n-button>
        </div>
        <!-- <n-divider />

        <rb-select
            multiple
            labelPosition="left"
            :defaultValue="false"
            :label="bots.localization['bot_notifications_select_phone_number_f']"
            :options="phonesOptions"
            v-model:value="dataForm.notifications_contacts.sms" />
        <n-button
            strong
            class="w-full rounded-md text-white/90 mt-4"
            :color="gl.mainColor"
            @click="addPhoneModalShow = true" >
            <strong>+</strong>&nbsp;
            {{ bots.localization['bot_notifications_select_phone_number_add_new_phone_number_modal_title_f'] }}
        </n-button> -->
    </div>
</n-collapse-transition>

<base-drawer-mobile
    :label="bots.localization['bot_notifications_select_email_add_new_email_modal_title_f']"
    v-model:show="addEmailModalShow">
    <template #default>
        <rb-input
            v-model:value="email"
            :placeholder="bots.localization['bot_notifications_select_email_add_new_email_modal_title_i']" />
    </template>
    <template #footer>
        <n-button
            class="rouned-md text-white/90 w-full"
            :color="gl.mainColor"
            :disabled="addEmailLoading"
            :loading="addEmailLoading"
            @click="onAddEmail" >
            {{ bots.localization['bot_notifications_select_email_add_new_email_button'] }}
        </n-button>
    </template>
</base-drawer-mobile>

<base-drawer-mobile
    :label="bots.localization['bot_notifications_select_phone_number_add_new_phone_number_modal_title_f']"
    v-model:show="addPhoneModalShow">
    <template #default>
        <rb-input
            v-model:value="phone"
            :placeholder="bots.localization['bot_notifications_select_phone_number_add_new_phone_number_modal_title_i']" />
    </template>
    <template #footer>
        <n-button
            class="rouned-md text-white/90 w-full"
            :color="gl.mainColor"
            :disabled="addPhoneLoading"
            :loading="addPhoneLoading"
            @click="onAddPhone" >
            {{ bots.localization['bot_notifications_select_phone_number_add_new_phone_number_modal_add_phone_button'] }}
        </n-button>
    </template>
</base-drawer-mobile>
</template>

<script>
import { ref, computed } from 'vue';

// store
import { useGl } from '@/store/gl';
import { useRefs } from '@/store/refs';
import { useBots } from '@/store/bots';

// services
import BotsService from '@services/bots';

// i18n
import { useI18n } from 'vue-i18n';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NModal,
    NInput,
    NButton,
    NDivider,
    NPopover,
    useMessage,
    NButtonGroup,
    useNotification,
    NCollapseTransition } from 'naive-ui';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';
import RbCheckboxGroup from '@components/rb-checkbox-group/mobile.vue';

// icons
import { Dismiss16Filled, QuestionCircle20Filled, IosArrowRtl24Filled, Checkmark12Filled } from '@vicons/fluent';

export default {
    name: 'bot-notifications-mobile',
    components: {
        NIcon,
        NCard,
        NInput,
        NAlert,
        NModal,
        RbInput,
        NButton,
        NPopover,
        NDivider,
        RbSelect,
        RbCheckbox,
        NButtonGroup,
        Dismiss16Filled,
        RbCheckboxGroup,
        Checkmark12Filled,
        IosArrowRtl24Filled,
        NCollapseTransition,
        QuestionCircle20Filled,
    },
    setup(props) {
        // store
        const gl = useGl();
        const bots = useBots();
        const refs = useRefs();

        // i18n
        const { t } = useI18n();

        // naive-ui
        const message = useMessage();
        const notification = useNotification();

        // vars
        const dataForm = bots.dataForm;
        const { _ } = window;
        const email = ref('');
        const phone = ref('');
        const addEmailLoading = ref(false);
        const addEmailModalShow = ref(false);
        const addPhoneLoading = ref(false);
        const addPhoneModalShow = ref(false);
        const show = ref(dataForm.use_custom_notifications);

        const exchangeCommissionCoin = computed(() => refs.exchanges.find(({ id }) => id === dataForm.exchange.value)?.commissionCoin || false);
        const emailOptions = computed(() => bots.notificationsContacts.email.map(el => ({
            label: el.title,
            value: el.id,
            ...el,
        })).filter(({ id }) => id));

        const phonesOptions = computed(() => bots.notificationsContacts.sms.map(el => ({
            label: el.title,
            value: el.id,
            ...el,
        })).filter(({ id }) => id));

        const onAddEmail = async () => {
            addEmailLoading.value = true;

            try {
                var result = await BotsService.addEmail({
                    email_address: email.value,
                    _formPath: 'addEmailModal',
                });

                if (!result.data.status) {
                    notification.error({
                        content: result.data?.errors_form.addEmailModal.fields.email.msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                } else {
                    // console.log('kek');
                }
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };

            addEmailLoading.value = false;
        };

        const onAddPhone = async () => {
            addPhoneLoading.value = true;

            try {
                var result = await BotsService.addPhone({
                    phone_number: phone.value,
                    _formPath: 'addPhoneNumber',
                });

                if (!result.data.status) {
                    notification.error({
                        content: result.data?.errors_form.addPhoneNumber.fields.phone.msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                }
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };

            addPhoneLoading.value = false;
        };

        const updateNotificationsValues = ( arr, $event ) => {
            const notif = arr.find(({ name }) => name === $event.name);

            if (!notif) return;

            if ($event.reset) {
                notif.values.splice(0);
            } else {
                const i = notif.values.indexOf($event.id);

                !~i
                    ? notif.values.push($event.id)
                    : notif.values.splice(i, 1);
            };
        };

        return {
            gl,
            show,
            bots,
            email,
            phone,
            dataForm,
            emailOptions,
            phonesOptions,
            addEmailLoading,
            addPhoneLoading,
            addPhoneModalShow,
            addEmailModalShow,
            exchangeCommissionCoin,
            updateNotificationsValues,
            onAddEmail,
            onAddPhone,
        };
    },
};
</script>