<template>
<n-collapse arrow-placement="right">
    <n-collapse-item class="ml-0">
        <template #header>
            <div class="text-md text-gray-600 dark:text-white/75 font-semibold">{{ bots.localization['bot_additional_settings_title'] }}</div>
        </template>
        <div>
            <!-- scalping -->
            <template v-if="isSkalpingModeAvail">
                <rb-checkbox
                    class="mt-4"
                    :label="'- ' + bots.localization['bots/additional_settings/scalping/label']"
                    :help="bots.help['bots/additional_settings/scalping/label']"
                    v-model:checked="dataForm.settings.scalping" />
                <n-divider class="my-4" />
            </template>
            
            <!-- revenue_to_depo_factor -->
            <template v-if="!isWalletTypePercent">
                
                <div class="flex items-center pr-2">
                    <rb-checkbox
                        class="w-3/12 pr-2"
                        :label="'- ' + bots.localization['bot_additional_settings_add_revenue_to_depo_checkbox']"
                        :help="bots.help['add_revenue_to_depo']"
                        v-model:checked="dataForm.settings.revenue_to_depo" />

                    <rb-select
                        v-if="dataForm.settings.revenue_to_depo"
                        class="px-2 w-3/12"
                        :defaultValue="-1"
                        :options="revenueToDepoFactorOptions"
                        v-model:value="dataForm.settings.revenue_to_depo_factor" />
                </div>
                <n-divider class="my-4" />
            </template>

            <!-- logarithmic_factor -->
            <!-- v-if="!isWalletTypePercent" -->
            <div class="flex items-start">
                <rb-checkbox
                    class="w-3/12 pr-2"
                    :label="'- ' + bots.localization['bot_additional_settings_logarithmic_scale_checkbox']"
                    :help="bots.help['logarithmic_scale_for_orders']"
                    v-model:checked="dataForm.settings.logarithmic_scale" />

                <rb-select
                    v-if="dataForm.settings.logarithmic_scale"
                    class="w-3/12 px-2"
                    :defaultValue="-1"
                    :label-width="345"
                    :label="bots.localization['bot_additional_settings_logarithmic_scale_factor']"
                    :options="logarithmicFactorOptions"
                    v-model:value="dataForm.settings.logarithmic_factor">
                    <template #afterTitle>
                        <n-popover
                            trigger="hover"
                            placement="top"
                            class="bg-main rounded-md">
                            <template #trigger>
                                <n-button
                                    strong
                                    size="tiny"
                                    class="ml-2"
                                    :color="gl.mainColor"
                                    :loading="orderMatrixLoading"
                                    :disabled="dataForm.settings.logarithmic_factor == -1 || orderMatrixLoading"
                                    @click="onViewOrderMatrix(dataForm.settings.order_matrix)">
                                    <n-icon class="text-xl text-white">
                                        <CellularData220Filled />
                                    </n-icon>
                                </n-button>
                            </template>
                            <span class="text-white text-xs" v-html="bots.localization['bot_orders_matrix_view_orders_matrix_button_title']"></span>
                        </n-popover>
                    </template>
                </rb-select>
            </div>
            <n-divider class="my-4" />

            <!-- cost_limit_enabled -->
            <div class="flex items-center">
                <rb-checkbox
                    class="w-3/12 pr-2"
                    :label="'- ' + bots.localization['bot_additional_settings_cost_limit_checkbox']"
                    :help="bots.help['cost_limit']"
                    v-model:checked="dataForm.settings.cost_limit_enabled" />
                <div class="w-3/12 px-2">
                    <n-input
                        v-if="dataForm.settings.cost_limit_enabled"
                        clearable
                        class="rounded-md"
                        type="text"
                        :placeholder="null"
                        :allow-input="onlyAllowNumber"
                        v-model:value="dataForm.settings.cost_limit"/>
                </div>
            </div>
            <n-divider class="my-4" />

            <!-- fix_first_partial_order -->
            <template v-if="!dataForm.settings.simulate">
                <div class="flex items-center">
                    <rb-checkbox
                        class="w-3/12 pr-2"
                        :label="'- ' + bots.localization['bot_additional_settings_fix_first_partial_checkbox']"
                        :help="bots.help['fix_first_partial_order_with_profit']"
                        v-model:checked="dataForm.settings.fix_first_partial_order" />

                    <rb-select
                        v-if="dataForm.settings.fix_first_partial_order"
                        class="w-3/12 px-2"
                        :defaultValue="-1"
                        :options="firstPartialOrderOptions"
                        v-model:value="dataForm.settings.fix_first_partial_order_value" />
                </div>
                <n-divider class="my-4" />
            </template>

            <!-- autorestart -->
            <rb-select
                class="w-4/12 pr-2"
                :defaultValue="-1"
                :label="bots.localization['bots/settings/autorestart/label']"
                :options="autorestartOptions"
                v-model:value="dataForm.settings.autorestart" />
            <n-divider class="my-4" />
            
            <template v-if="partOrderValueShow">
                <rb-checkbox
                    class="w-3/12 pr-2"
                    :label="'- ' + (bots.localization['bots/additional_settings/change_profit_setting/label'] || 'Изменить настройку профита')"
                    :help="bots.help['bots/additional_settings/change_profit_setting/help'] || 'Help'"
                    v-model:checked="dataForm.settings.profit_advanced_enabled" />

                <section class="flex flex-wrap mt-4 items-stretch">
                    <div
                        v-for="(item, i) in dataForm.settings.profit_advanced"
                        :key="i"
                        class="odd:pr-1 even:pl-1 mb-2 w-6/12">
                        <n-card
                            :bordered="false"
                            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg relative"
                            :segmented="{
                                content: true,
                                footer: 'soft'
                            }">
                            <n-icon
                                v-if="i"
                                class="text-red-500 text-2xl ml-4 cursor-pointer absolute top-2 right-2"
                                @click="deleteProfitAdvanced(i)">
                                <DeleteDismiss24Regular />
                            </n-icon>
                            
                            <div class="flex mt-4">
                                <!-- order_i -->
                                <rb-select
                                    class="w-6/12 pr-1"
                                    :label="bots.localization['bots/additional_settings/order_number/label'] || 'Номер ордера'"
                                    :options="profitAdvancedOptions[i]"
                                    v-model:value="item.order_i"
                                    @update:value="updateOrderI(i, $event)" />
                                <!-- profit -->
                                <rb-select
                                    class="w-6/12 pl-1"
                                    :defaultValue="-1"
                                    :label="bots.localization['bot_profit_f']"
                                    :help="bots.help['profit']"
                                    :options="profitOptions"
                                    v-model:value="item.profit" />
                            </div>
                        </n-card>
                    </div>
                    <div v-if="showAddProfitAdvanced" class="odd:pr-1 even:pl-1 mb-2 w-6/12">
                        <n-button
                            secondary
                            size="large"
                            type="default"
                            class="w-full h-full rounded-lg min-h-[120px] shadow"
                            @click="addProfitAdvanced">
                            +
                        </n-button>
                    </div>
                </section>
            </template>
        </div>
    </n-collapse-item>
</n-collapse>

<base-modal-desktop
    width="800px"
    :label="bots.localization['bot_additional_settings_logarithmic_scale_view_orders_matrix_button']"
    v-model:show="viewOrdersMatrixModal">
    <template #default>
        <section>
            <div class="flex justify-center items-center">
                <span class="text-base" v-html="bots.localization['bot_orders_matrix_view_orders_matrix_modal_current']"></span>
                <span
                    class="inline-block rb-td-stock-icon mx-4"
                    :class="`rb-td-stock-icon-${exchange(dataForm.exchange.value)?.code_name}`" >
                </span>
                <span class="text-base" v-html="bots.localization['bot_orders_matrix_view_orders_matrix_modal_tickers_for']"></span>
                <div class="flex items-center mx-4">
                    <rb-coin class="w-6" :coin="currency(String(botShortInfo.pair).split('/').shift().trim())" />
                    <div class="text-md text-gray-600 dark:text-white/75 ml-2">{{ botShortInfo.pair }}</div>
                </div>
                <span class="text-md" v-html="bots.localization['bot_orders_matrix_view_orders_matrix_modal_currenttickers_buy']"></span>&nbsp;
                <strong class="text-md pl-1 text-green-400">{{ botShortInfo.buy }}</strong>
                <span class="text-md pl-3" v-html="bots.localization['bot_orders_matrix_view_orders_matrix_modal_currenttickers_sell']"></span>&nbsp;
                <strong class="text-red-500 text-md pl-1">{{ botShortInfo.sell }}</strong>
                <n-button
                    strong
                    class="ml-4 rounded-md text-white/90"
                    :color="gl.mainColor"
                    :loading="orderMatrixLoading"
                    :disabled="orderMatrixLoading"
                    @click="onViewOrderMatrix">
                    {{ bots.localization['bot_orders_matrix_view_orders_matrix_modal_update'] }}
                </n-button>
            </div>
            <div class="mt-4 notes">
                <div v-if="dataForm.algo.value == 'long'" v-html="prepareNote(bots.localization['bot_orders_matrix_view_orders_matrix_modal_long_info'])"></div>
                <div v-else v-html="prepareNote(bots.localization['bot_orders_matrix_view_orders_matrix_modal_short_info'])"></div>
            </div>
        </section>
        <n-divider />

        <section>
            <div
                v-for="(item, i) in botShortInfo.orders_matrix"
                :key="i"
                class="flex text-md items-center">
                <div class="mr-2">{{ i + 1 }}.</div>
                <div class="mr-2">{{ bots.localization['bot_orders_matrix_view_orders_matrix_modal_amount'] }}</div>
                <div class="mr-2 text-main">{{ item.amount }}</div>
                <strong class="mr-2">{{ dataForm.algo.value === 'long' ? 'Buy' : 'Sell' }}</strong>
                <div class="mr-2">{{ bots.localization['bot_orders_matrix_view_orders_matrix_modal_rate'] }}</div>
                <div class="mr-2 text-green-400">{{ item.rate }}</div>
            </div>
        </section>
    </template>
</base-modal-desktop>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NIcon,
    NCard,
    NInput,
    NModal,
    NAlert,
    NSelect,
    NButton,
    NPopover,
    NDivider,
    NCollapse,
    NCheckbox,
    NCollapseItem } from 'naive-ui';

// components
import RbSelect from '@components/rb-select';
import RbCheckbox from '@components/rb-checkbox';

// icons
import {
    Dismiss16Filled,
    DeleteDismiss24Regular,
    CellularData220Filled,
    QuestionCircle20Filled } from '@vicons/fluent';
    
export default {
    name: 'botAdditionalSettings',
    emits: [
        'update:some',
    ],
    components: {
        NIcon,
        NCard,
        NAlert,
        NInput,
        NModal,
        NSelect,
        NButton,
        NPopover,
        NDivider,
        RbSelect,
        NCollapse,
        NCheckbox,
        RbCheckbox,
        NCollapseItem,
        Dismiss16Filled,
        DeleteDismiss24Regular,
        CellularData220Filled,
        QuestionCircle20Filled,
    },
    props: {
        walletsRefs: {
            type: [Array, Object],
            required: true,
        },
    },
    setup(props) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>