<template>
<div class="flex items-center cursor-pointer" @click="show = !show">
    <div class="text-md text-gray-600 dark:text-white/75 font-semibold">{{ bots.localization['bot_start_filters_title'] }}</div>
    <n-icon class="text-sm ml-2 transition transform text-base" :class="show ? 'rotate-90' : ''">
        <IosArrowRtl24Filled />
    </n-icon>
</div>
<n-collapse-transition :show="show">
    <n-button v-if="dataForm.start_filters_enabled"
        strong
        secondary
        size="small"
        class="rounded-md mt-4 w-full"
        :disabled="!dataForm.start_filters.length"
        @click.prevent.stop="showCreateNewPreset = true">
        {{ bots.localization?.bot_settings_filters_save_button || 'Сохранить шаблон фильтров' }}
    </n-button>
    <div class="mt-4">
        <rb-checkbox
            :label="'- ' + bots.localization['bot_start_filters_enable_checkbox']"
            :helpWarning="dataForm.start_filters_enabled ? bots.localization['bots_bot_start_filters'] : null"
            v-model:checked="dataForm.start_filters_enabled" />
        <div v-if="dataForm.start_filters_enabled" class="mt-4">
            <rb-select
                v-if="dataForm.start_filters_enabled"
                class="w-full mb-4"
                :label="(bots.localization?.filters_template_title || 'Шаблоны фильтров') + ':'"
                :options="filterPresetsOptions"
                v-model:value="filterId"
                @update:value="changeFilterPresets" />
            <div
                v-for="(filter, i) in dataForm.start_filters"
                :key="i+'_'+filter.id+'_'+filter.id_op"
                class="mb-4">
                <n-card
                    size="small"
                    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg relative"
                    :segmented="{
                        content: true,
                        footer: 'soft'
                    }">
                    <n-icon
                        class="text-red-500 text-2xl ml-4 cursor-pointer absolute top-2 right-2"
                        @click="onDeleteStartFilter(i)">
                        <DeleteDismiss24Regular />
                    </n-icon>
                    
                    <div class="flex flex-wrap mt-4">
                        <rb-select
                            class="w-6/12 odd:pr-1 even:pl-1 mb-2"
                            :label="bots.localization['bot_start_filters_filter_number'] + `${i + 1}:`"
                            :options="startFiltersOptions"
                            v-model:value="dataForm.start_filters[i].id"
                            @update:value="updateStartFilters({ i, key: 'id',  value: $event || -1 })" />
                        <rb-select
                            class="w-6/12 odd:pr-1 even:pl-1 mb-2"
                            :label="bots.localization['bot_start_filters_filter_operation'] + `${i + 1}:`"
                            :options="startFiltersOptOptions"
                            :value="dataForm.start_filters[i].id_op"
                            @update:value="updateStartFilters({ i, key: 'id_op',  value: $event || -1 })" />
                        <rb-input
                            class="w-6/12 odd:pr-1 even:pl-1 mb-2"
                            :label="bots.localization['bot_start_filters_filter_value'] + `${i + 1}:`"
                            :value="dataForm.start_filters[i].value"
                            @update:value="updateStartFilters({ i, key: 'value',  value: $event })" />
                        <rb-input
                            class="w-6/12 odd:pr-1 even:pl-1 mb-2"
                            :class="startFilterHasPair(filter.id, i) ? 'px-1' : 'pl-1'"
                            :label="bots.localization['bot_start_filters_params_name']"
                            :value="dataForm.start_filters[i].data"
                            @update:value="updateStartFilters({ i, key: 'data',  value: $event })" />
                        <rb-pair-select
                            v-if="startFilterHasPair(filter.id, i)"
                            class="mb-2 w-full"
                            :options="pairsRefsOptions"
                            :label="bots.localization['pair_f']"
                            :value="dataForm.start_filters[i].pair"
                            :placeholder="bots.localization['bots/start_filters/current_pair/label']"
                            @update:value="updateStartFilters({ i, key: 'pair',  value: $event })"  />
                    </div>
                </n-card>
            </div>
            <n-button
                strong
                secondary
                class="rounded-md w-full mt-4"
                @click="onAddNewStartFilter">
                + {{ bots.localization?.add_new_start_filter || 'Add new start filter' }}
            </n-button>

            <router-link class="block mt-4" :to="{ name: 'tools.presets', params: { tab: 'start-filters-presets' } }" >
            <n-button
                secondary
                class="rounded-md font-semibold w-full">
                {{ bots.localization?.bot_template_management_label || 'Управление шаблонами' }}
            </n-button>
        </router-link>
        </div>
    </div>
</n-collapse-transition>
<base-drawer-mobile
    :label="bots.localization?.bot_settings_filters_save_modal_title || 'Новый шаблон фильтров'"
    v-model:show="showCreateNewPreset">
    <template #default>
        <rb-input
            class="mt-4"
            :label="newPresetModel.title.title"
            :status="newPresetModel.title.status"
            :msg="newPresetModel.title.msg"
            :placeholder="newPresetModel.title.placeholder"
            v-model:value="newPresetModel.title.value"
            @update:value="newPresetModel.title.msg = null, newPresetModel.title.status = null" />
        <rb-input
            class="mt-4"
            type="textarea"
            :label="newPresetModel.description.title"
            :status="newPresetModel.description.status"
            :msg="newPresetModel.description.msg"
            :placeholder="newPresetModel.description.placeholder"
            v-model:value="newPresetModel.description.value" />
    </template>
    <template #footer>
        <n-button
            strong
            class="rounded-md text-white/90 w-full"
            :color="gl.mainColor"
            :disabled="loading"
            :loading="loading"
            @click="onCreateNewFiltersPreset">
            {{ bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_save_button'] }}
        </n-button>
    </template>
</base-drawer-mobile>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NCollapseTransition } from 'naive-ui';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';
import RbPairSelect from '@components/rb-pair-select/mobile.vue';

// icons
import { Dismiss16Filled, IosArrowRtl24Filled, DeleteDismiss24Regular } from '@vicons/fluent';

export default {
    name: 'bot-start-filters-mobile',
    props: {
        pairsRefs: {
            type: Array,
            required: true,
        },
    },
    components: {
        NIcon,
        NCard,
        NAlert,
        RbInput,
        NButton,
        NDivider,
        RbSelect,
        RbCheckbox,
        RbPairSelect,
        Dismiss16Filled,
        IosArrowRtl24Filled,
        NCollapseTransition,
        DeleteDismiss24Regular,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>