import { ref, reactive, computed, watch } from 'vue';

// store
import { useGl } from '@store/gl';
import { useRefs } from '@store/refs';
import { useBots } from '@store/bots';

// i18n
import { useI18n } from 'vue-i18n';

export default function (props, context) {
    // store
    const gl = useGl();
    const bots = useBots();
    const refs = useRefs();

    // i18n
    const { t } = useI18n();

    // vars
    const dataForm = bots.dataForm;
    const show = ref(dataForm.switch_tp.enabled);
    const messageInfo = reactive({
        type: '',
        msg: '',
    });

    const isExchangeFutures = computed(() => !!refs.exchanges.find(({ id }) => id === dataForm.exchange.value)?.futures);
    const switchTypesOptions = computed(() => bots.switchTypes.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    watch(() => dataForm.switch_tp.enabled, v => {
        v
            ? console.log('checked true')
            : hideCollapse();
    });

    const hideCollapse = () => {
        show.value = false;
    };

    return {
        gl,
        bots,
        refs,
        show,
        dataForm,
        messageInfo,
        isExchangeFutures,
        switchTypesOptions,
        t,
    };
}