// vue
import { ref, watch, reactive, computed } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';

// i18n
import { useI18n } from 'vue-i18n';

// services
import BotsService from '@services/bots';

// naive-ui
import { useNotification } from 'naive-ui';

export default function (props, { emit }) {
    // store
    const gl = useGl();
    const bots = useBots();
    const refs = useRefs();

    // i18n
    const { t } = useI18n();

    // ui
    const notification = useNotification();

    // vars
    const { _ } = window;
    const filterId = ref();
    const showCreateNewPreset = ref(false);
    const presetFiltersList = ref([]);
    const dataForm = bots.dataForm;
    const show = ref(dataForm.start_filters_enabled);
    const loading = ref(false);

    watch(showCreateNewPreset, v => {
        if (!v) {
            newPresetModel.title.value = '';
            newPresetModel.description.value = '';
        }
    });

    const newPresetModel = reactive({
        title: {
            value: '',
            title: bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_name_f'],
            placeholder: bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_name_f'],
            info: undefined,
            status: undefined,
            msg: undefined,
        },
        description: {
            value: '',
            title: bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_description_i'],
            placeholder: bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_description_i'],
            info: undefined,
            status: undefined,
            msg: undefined,
        },
        algo: {
            value: computed(() => _.get(dataForm, 'algo.value')),
        },
        start_filters: {
            value: computed(() => dataForm.start_filters),
        },
    });

    const startFiltersOptions = computed(() => bots.startFilters.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const startFiltersOptOptions = computed(() => bots.startFilterOpts.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const pairsRefsOptions = computed(() => {
        return props.pairsRefs.map(cur => {
            return {
                label: cur.title,
                value: cur.id,
                ...cur,
            };
        });
    });

    const filterPresetsOptions = computed(() => {
        const algo = _.get(dataForm, 'algo.value');
        
        return bots.filterPresets.map(el => ({
            label: el.name,
            value: el.id,
            ...el,
        })).filter(el => el.algo == algo);
    });

    const onAddNewStartFilter = () => {
        dataForm.start_filters = [ ..._.get(dataForm, 'start_filters', []), { id: -1, id_op: -1, value: '' } ];
    };

    const startFilterHasPair = (id, i) => {
        const res = !!bots.startFilters.find(el => el.id === id);

        if (!res)
            emit('update:start_filters', { i, key: 'pair',  value: null });

        return res;
    };

    const onDeleteStartFilter = $event => {
        dataForm.start_filters.splice($event, 1);
        if (dataForm.start_filters.length === 0)
            dataForm.start_filters = [{ id: -1, id_op: -1, value: '' }];
    };

    const updateStartFilters = $event => {
        dataForm.start_filters[$event.i][$event.key] = $event.value;
    };

    const changeFilterPresets = $event => {
        const filter = bots.filterPresets.find(({ id }) => id == $event);
        dataForm.start_filters = dataForm.start_filters.filter(({ id }) => !~presetFiltersList.value.findIndex(i => i == id));
        presetFiltersList.value = [];

        if (filter) {
            filter.filters.start_filters.forEach(({ data, id_op, id, pair, value }) => {
                presetFiltersList.value.push(id);

                dataForm.start_filters.push({
                    data,
                    id,
                    id_op,
                    pair,
                    value,
                });
            });
        }
    };

    const onCreateNewFiltersPreset = async () => {
        loading.value = true;

        try {
            const result = await BotsService.createNewFiltersPreset({
                title: newPresetModel.title.value,
                description: newPresetModel.description.value,
                algo: newPresetModel.algo.value,
                start_filters: newPresetModel.start_filters.value,
                _formPath: 'createNewFiltersPreset',
            });

            console.log('result', result);
            
            if (result) {
                if (!result?.data.status) {
                    if (result.data?.errors_form) {

                        for (let key in newPresetModel) {
                            const fields = result.data.errors_form.createNewFiltersPreset.fields;
                            const el = Object.keys(fields).find(el => el === key);

                            if (el) {
                                newPresetModel[key].status = 'error';
                                newPresetModel[key].msg = fields[el].msg;
                            } else {
                                newPresetModel[key].status = 'success';
                                newPresetModel[key].msg = undefined;
                            }
                        }
                    }
                } else {
                    // show messages
                    if (result?.postMessages)
                        result.postMessages.forEach(el => {
                            notification[el.success ? 'success' : 'error']({
                                content: el.msg,
                                duration: 2500,
                                keepAliveOnHover: true,
                            });
                        });

                    bots.refs.filter_presets.push({
                        name: result.data.preset.title,
                        ...result.data.preset,
                    });

                    newPresetModel.title.value = '';
                    newPresetModel.description.value = '';

                    showCreateNewPreset.value = false;
                }
            }
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };

        loading.value = false;
    };

    return {
        gl,
        show,
        bots,
        loading,
        filterId,
        dataForm,
        newPresetModel,
        pairsRefsOptions,
        showCreateNewPreset,
        startFiltersOptions,
        filterPresetsOptions,
        startFiltersOptOptions,
        updateStartFilters,
        startFilterHasPair,
        onAddNewStartFilter,
        onDeleteStartFilter,
        changeFilterPresets,
        onCreateNewFiltersPreset,
    };
}