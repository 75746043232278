<template>
<section class="text-left">
    <div class="text-md text-gray-600 dark:text-white/75 font-semibold">{{ bots.localization['bots/auto_switch/signals/tabs/main/title'] }}</div>
    <div class="mt-4">
        <div class="flex items-center">
            <div class="flex items-center">
                <div class="text-sm whitespace-nowrap">{{ bots.localization['bot_auto_switch_type_volatility_analyzer_exchange'] }}</div>
                <n-popover
                    trigger="hover"
                    placement="top"
                    class="rounded-md bg-main">
                    <template #trigger>
                        <n-icon class="text-main cursor-pointer text-xl mx-2">
                            <QuestionCircle20Filled />
                        </n-icon>
                    </template>
                    <span class="text-white" v-html="bots.help['switch_exchange']"></span>
                </n-popover>
            </div>
            <span
                class="inline-block rb-td-stock-icon mx-0 h-6 "
                :class="`rb-td-stock-icon-${exchange(dataForm.exchange.value)?.code_name}`" >
            </span>
        </div>
        <div class="flex items-center mt-4">
            <div class="flex items-center">
                <div class="text-sm whitespace-nowrap">{{ bots.localization['bot_auto_switch_type_volatility_analyzer_market'] }}</div>
                <n-popover
                    trigger="hover"
                    placement="top"
                    class="rounded-md bg-main max-w-[300px]">
                    <template #trigger>
                        <n-icon class="text-main cursor-pointer text-xl mx-2">
                            <QuestionCircle20Filled />
                        </n-icon>
                    </template>
                    <span class="text-white" v-html="bots.help['switch_market']"></span>
                </n-popover>
            </div>
            <div class="flex items-center">
                <rb-coin class="w-6" :coin="currency(dataForm.settings.profit_coin)" />
                <div class="font-semibold ml-4">{{ currency(dataForm.settings.profit_coin).title }}</div>
            </div>
        </div>
    </div>
    <n-divider />

    <rb-switch
        largeHelp
        :label="bots.localization['bot_auto_switch_type_volatility_analyzer_black_white_lists']"
        :help="bots.help['switch_use_bw_list']"
        v-model:value="dataForm.switch_tp.use_bw_list" />
    <n-divider />

    <template v-if="symbolsForMarketRefsReady && dataForm.switch_tp.use_bw_list">
        <rb-switch
            :label="bots.localization['bot_auto_switch_type_volatility_analyzer_set_white_list']"
            :value="dataForm.switch_tp.is_white_list"
            @update:value="dataForm.switch_tp.is_white_list = $event"/>

        <rb-switch
            class="mt-4"
            :label="bots.localization['bot_auto_switch_type_volatility_analyzer_set_black_list']"
            :value="!dataForm.switch_tp.is_white_list"
            @update:value="dataForm.switch_tp.is_white_list = !$event"/>

        <rb-select
            class="mt-4"
            :label="bots.localization['bots/va/settings/coin_list/select_strategy']"
            :options="coinListStrategiesOptions"
            :help="bots.help['coin_list_strategy']"
            v-model:value="dataForm.switch_tp.coin_list_strategy" />

        <rb-select
            v-if="dataForm.switch_tp.coin_list_strategy != -1 && dataForm.switch_tp.coin_list_strategy != 'local'"
            largeHelp
            clearable
            class="mt-4"
            :label="bots.localization['bots/va/settings/coin_list/shared_coin_list']"
            :options="sharedCoinListItems"
            :help="bots.help['shared_coin_list']"
            v-model:value="dataForm.switch_tp.shared_coin_list" />
        <n-divider />
        <!-- coin list selector -->
        <template v-if="canShowSharedCoinList">
            <rb-coin-list
                multiple
                disabled
                class="w-full"
                :label="bots.localization['bots/va/settings/coin_list/shared_list_coins']"
                :dataHelp="bots.help['shared_list_coins']"
                :value="sharedCoinList"
                :options="sharedCoinListRef" />
            <n-button
                strong
                class="rounded-md mt-4 text-white/90 w-full"
                :color="gl.mainColor"
                @click="router.push({name: 'tools.va', query: { tab: 'rbpsCoinList' }})">
                {{ bots.localization['bots/va/settings/coin_list/create_update_coin_list'] }}
            </n-button>
            <n-divider />
        </template>
        <div v-if="dataForm.switch_tp.coin_list_strategy != 'shared' && dataForm.switch_tp.coin_list_strategy != -1">
            <rb-coin-list
                multiple
                class="w-full"
                :label="bots.localization['bots/va/settings/coin_list/local_list_coins']"
                v-model:value="dataForm.switch_tp.bw_list"
                :options="symbolsForMarketRefsOptions" />
            <n-divider />
        </div>
    </template>

    <section>
        <rb-input
            :label="bots.localization['bot_auto_switch_type_volatility_analyzer_min_trade_volume_f']"
            :help="bots.help['switch_min_trade_volume']"
            :placeholder="bots.localization['bot_auto_switch_type_volatility_analyzer_min_trade_volume_i']"
            v-model:value="dataForm.switch_tp.min_trade_volume" />

        <rb-input
            class="mt-4"
            :label="bots.localization['bot_auto_switch_type_volatility_analyzer_min_price_f']"
            :help="bots.help['switch_min_price']"
            :placeholder="bots.localization['bot_auto_switch_type_volatility_analyzer_min_price_i']"
            v-model:value="dataForm.switch_tp.min_price" />
    </section>
    <n-divider />

    <!-- PUMP/DUMP filters -->
    <section>
        <div class="flex mb-4">
            <div class="text-xs text-gray-600 dark:text-white/75 whitespace-nowrap">
                {{ bots.localization['bot_auto_switch_type_volatility_analyzer_pump_dump_filters_title'] }}
            </div>
            <n-popover
                scrollable
                trigger="hover"
                class="max-w-[200px] max-h-[200px] rounded-md bg-main"
                placement="top">
                    <template #trigger>
                        <n-icon class="text-main cursor-pointer text-xl mx-2">
                            <QuestionCircle20Filled />
                        </n-icon>
                    </template>
                <span class="text-xs text-white" v-html="bots.help['switch_pd_filters']"></span>
            </n-popover>
        </div>

        <div
            v-for="(pd_filter, i) in dataForm.switch_tp.pd_filters" :key="i"
            class="w-full">
            <n-card
                size="small"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg relative mb-4"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <n-icon
                    class="text-red-500 text-2xl ml-4 cursor-pointer absolute top-2 right-2"
                    @click="onDeletePDFilter(i)">
                    <DeleteDismiss24Regular />
                </n-icon>
                
                <div class="flex mt-4">
                    <rb-input
                        class="w-6/12 pr-1"
                        :label="bots.localization['bot_auto_switch_type_volatility_analyzer_pump_dump_filter_period']"
                        :help="bots.help['switch_pd_period']"
                        v-model:value="dataForm.switch_tp.pd_filters[i].pd_period" />
                    <rb-input
                        class="w-6/12 pl-1"
                        :label="bots.localization['bot_auto_switch_type_volatility_analyzer_rate_change_f']"
                        :help="bots.help['switch_pd_rate_change']"
                        v-model:value="dataForm.switch_tp.pd_filters[i].pd_rate_change" />
                </div>
            </n-card>
        </div>
        <n-button
            strong
            secondary
            class="w-full rounded-md"
            @click="onAddPDFilter">
            {{ bots.localization['bot_auto_switch_type_volatility_analyzer_pump_dump_filters_add_filter_button'] }}
        </n-button>
    </section>
    <n-divider />

    <!-- signals -->
    <div class="notes text-md text-gray-600 dark:text-white/75 font-semibold" v-html="bots.localization['bots/auto_switch/signals/title']"></div>
    <rb-checkbox
        class="mt-4"
        :label="'- ' + bots.localization['bots/auto_switch/signals/use_sl_from_signal/label']"
        :help="bots.help['use_sl_from_signal']"
        v-model:checked="dataForm.switch_tp.use_sl_from_signal" />

    <rb-checkbox
        class="mt-4"
        :label="'- ' + bots.localization['bots/auto_switch/signals/use_profit_from_signal/label']"
        :help="bots.help['use_profit_from_signal']"
        v-model:checked="dataForm.switch_tp.use_profit_from_signal" />
        
    <rb-select
        v-if="availableSignalsRef?.length > 0"
        multiple
        class="mt-4"
        :defaultValue="null"
        :placeholder="bots.localization['bots/auto_switch/signals/placeholder']"
        :options="availableSignalsRef"
        :value="usedSignalsModelValue"
        @update:value="$event ? dataForm.switch_tp.signals = $event : null" />
</section>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRouter } from 'vue-router';

// shared
import { CRUD_PAGER_FACTORY, VA_PD_FILTER_FACTORY } from '@shared/factories';

// naive-ui
import {
    NTabs,
    NIcon,
    NCard,
    NSelect,
    NButton,
    NSwitch,
    NPopover,
    NDivider,
    NTabPane,
    useMessage } from 'naive-ui';

// components
import RbCoin from '@components/rb-coin';
import RbSwitch from '@components/rb-switch/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';
import RbCoinList from '@components/rb-coin-list/mobile.vue';
import RbInput from '@components/rb-input/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';

// util
import { formatUnexistedCurrsAsUnknown } from '@shared/util';

// services
import ToolsService from '@services/tools';
import MarketplaceService from '@services/marketplace';

// icons
import { Dismiss16Filled, QuestionCircle20Filled, DeleteDismiss24Regular } from '@vicons/fluent';

export default {
    name: 'BotSignalSwitch',
    props: {
        symbolsForMarketRefs: {
            type: Object,
            required: true,
        },
    },
    components: {
        NCard,
        NTabs,
        NIcon,
        RbCoin,
        RbInput,
        NButton,
        NSwitch,
        NSelect,
        NTabPane,
        NPopover,
        NDivider,
        RbSwitch,
        RbSelect,
        RbCoinList,
        RbCheckbox,
        Dismiss16Filled,
        DeleteDismiss24Regular,
        QuestionCircle20Filled,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();
        const bots = useBots();
        const refs = useRefs();

        // i18n
        const { t } = useI18n();

        // router
        const router = useRouter();

        // naive-ui
        const message = useMessage();

        // vars
        const dataForm = bots.dataForm;
        const sharedCoinList = ref();
        const sharedCoinListRef = ref();
        const availableSignalsRef = ref();
        const vaResultsFetched = ref(false);
        const continueSavingPreset = ref(false);

        watch(() => dataForm.switch_tp.shared_coin_list, async v => {
            if (v === null) {
                sharedCoinList.value = null;
                return void emit('update:switch_tp-shared_coin_list', -1);
            }

            if (v != -1)
                await fetchSharedCoinListRef(v);
        });

        watch(() => dataForm.exchange.value, async v => {
            if (!v || v == -1) return;

            if (switchType.value === 'signal')
                await fetchAvailableSignalsRef();
        });

        const switchType = computed(() => dataForm.switch_tp.type);
        const symbolsForMarketRefsReady = computed(() => props.symbolsForMarketRefs ? Object.keys(props.symbolsForMarketRefs).length > 0 : false);
        const canShowSharedCoinList = computed(() => ['shared', 'local_and_shared'].includes(dataForm.switch_tp.coin_list_strategy) && dataForm.switch_tp.shared_coin_list != -1);
        const coinListStrategiesOptions = computed(() => bots.coinListStrategies.map(el => ({
            label: el.title,
            value: el.id,
            ...el,
        })));
        const symbolsForMarketRefsOptions = computed(() => Object.values(props.symbolsForMarketRefs).map(el => ({
            label: el.title,
            value: el.id,
            ...el,
        })));
        const usedSignalsModelValue = computed(() => (dataForm.switch_tp.signals || [])
            .map(s => window._.get(window._.keyBy(availableSignalsRef.value, 'id'), String(s))?.value)
            .filter(s => s));
        const sharedCoinListItems = computed(() => {
            if (dataForm.exchange.value == -1) return [];

            return Object.values(bots.coinLists)
                .filter(cl => cl.exchange == dataForm.exchange.value)
                .map(el => ({
                    label: el.title,
                    value: el.id,
                    ...el,
                }));
        });

        const invalidateForm = () => {
            vaResultsFetched.value = false;
            continueSavingPreset.value = false;
        };

        const currency = icon => refs.currencies.find(el => el.id === icon);
        const exchange = (exchange) => refs.exchanges.find(el => el.id === exchange);

        const fetchSharedCoinListRef = async (id) => {
            gl.showLoading = true;

            try {
                var data = await ToolsService.getCoinListInfo(id);
            } catch {
                message.error(t('errorMessage'));
            }

            if (!data?.data.status) {
                message['error'](data.data?.message);
            } else {
                sharedCoinList.value = data.data.coin_list.coins;
                sharedCoinListRef.value = formatUnexistedCurrsAsUnknown(refs, data.data.coin_list.coins).map(el => ({
                    value: el.id,
                    label: el.title,
                    ...el,
                }));
            };
            
            gl.showLoading = false;
        };

        const fetchAvailableSignalsRef = async () => {
            gl.showLoading = true;

            const pager = CRUD_PAGER_FACTORY({
                exchanges: [dataForm.exchange.value],
            }, { perPage: '*' });

            try {
                const prepare = await MarketplaceService.getMySignals(pager);

                if (!prepare?.data.status) {
                    message['error'](prepare.data?.message);
                } else {
                    availableSignalsRef.value = prepare.data.records
                        .filter(s => (s.exchanges || []).find(eId => eId == dataForm.exchange.value))
                        .map(el => ({
                            value: el.id,
                            label: el.name,
                            ...el,
                        }));
                };
            } catch {
                message.error(t('errorMessage'));
            }

            gl.showLoading = false;
        };

        const onDeletePDFilter = i => {
            dataForm.switch_tp.pd_filters.splice(i, 1);
        };

        const onAddPDFilter = () => {
            dataForm.switch_tp.pd_filters.push(VA_PD_FILTER_FACTORY());
            invalidateForm();
        };

        onMounted(() => {
            const id = dataForm.switch_tp.shared_coin_list;

            if (id != -1)
                fetchSharedCoinListRef(id);

            if (switchType.value === 'signal')
                fetchAvailableSignalsRef();
        });

        return {
            gl,
            bots,
            router,
            dataForm,
            sharedCoinList,
            sharedCoinListRef,
            availableSignalsRef,
            sharedCoinListItems,
            canShowSharedCoinList,
            usedSignalsModelValue,
            symbolsForMarketRefsReady,
            coinListStrategiesOptions,
            symbolsForMarketRefsOptions,
            currency,
            exchange,
            onAddPDFilter,
            invalidateForm,
            onDeletePDFilter,
        };
    },
};
</script>

<style lang="scss" scoped>
.filters {
    &:nth-child(3n + 2) {
        padding: 0 8px;
    }

    &:nth-child(3n + 1) {
        padding-right: 8px;
    }

    &:nth-child(3n + 3) {
        padding-left: 8px;
    }
}
</style>