<template>
<div
    class="text-md text-gray-600 dark:text-white/75 font-semibold cursor-pointer text-left flex items-center"
    @click="show = !show">
    {{ bots.localization['bot/algo_auto_inversion/title'] }}
    <n-icon class="text-sm ml-2 transition transform" :class="show ? 'rotate-90' : ''">
        <IosArrowRtl24Filled />
    </n-icon>
</div>
<n-collapse-transition :show="show">
    <div class="pt-4">
        <rb-checkbox
            :label="'- ' + bots.localization['bot/algo_auto_inversion/checkbox/title']"
            v-model:checked="dataForm.algo_autoinversion_enabled" />

        <n-alert
            v-if="messageInfo.type"
            class="mt-4 rounded-md"
            type="warning"
            size="small">
            <div class="notes text-xs" v-html="messageInfo.msg"></div>
        </n-alert>

        <rb-select
            v-if="botsAutoInvListOptions.length"
            class="mt-4"
            :defaultValue="-1"
            :label="bots.localization['bot/algo_auto_inversion/label']"
            :options="botsAutoInvListOptions"
            v-model:value="dataForm.algo_autoinversion_bot_id" />
    </div>
</n-collapse-transition>
</template>

<script>
import { ref, reactive, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@/store/gl';
import { useRefs } from '@/store/refs';
import { useBots } from '@/store/bots';

// naive-ui
import {
    NIcon,
    NCard,
    NInput,
    NModal,
    NAlert,
    NSelect,
    NButton,
    NPopover,
    NDivider,
    NCollapse,
    NCheckbox,
    useMessage,
    NCollapseItem,
    NCollapseTransition } from 'naive-ui';

// i18n
import { useI18n } from 'vue-i18n';

// services
import botsService from '@services/bots';

// components
import RbSelect from '@components/rb-select';
import RbCheckbox from '@components/rb-checkbox';

// shared
import { BOTS_DATA_FORM_FACTORY_PREPARE } from '@shared/factories';

// icons
import { Dismiss16Filled, CellularData220Filled, QuestionCircle20Filled, IosArrowRtl24Filled } from '@vicons/fluent';
    
export default {
    name: 'botAdditionalSettings',
    emits: [
        'update:algo_autoinversion_bot_id',
        'update:algo_autoinversion_enabled',
    ],
    components: {
        NIcon,
        NCard,
        NAlert,
        NInput,
        NModal,
        NSelect,
        NButton,
        RbSelect,
        NPopover,
        NDivider,
        NCollapse,
        NCheckbox,
        RbCheckbox,
        NCollapseItem,
        Dismiss16Filled,
        NCollapseTransition,
        IosArrowRtl24Filled,
        CellularData220Filled,
        QuestionCircle20Filled,
    },
    props: {
        walletsRefs: {
            type: [Array, Object],
            required: true,
        },
    },
    setup() {
        // store
        const gl = useGl();
        const bots = useBots();
        const refs = useRefs();

        // i18n
        const { t } = useI18n();

        // naive-ui
        const message = useMessage();

        // vars
        const dataForm = bots.dataForm;
        const show = ref(dataForm.algo_autoinversion_enabled);
        const collapse = ref();
        const messageInfo = reactive({
            type: '',
            msg: '',
        });
        const botsAutoInvRefs = ref();

        const botsAutoInvListOptions = computed(() => botsAutoInvRefs.value
            ? botsAutoInvRefs.value?.botsAutoInvList.map(el => ({
                label: el.title,
                value: el.id,
                ...el,
            })) : []);

        const hideCollapse = () => {
            show.value = false;
            botsAutoInvRefs.value = null;
            messageInfo.type = '';
        };

        const syncBotsAutoInvList = async () => {
            if (!dataForm.algo_autoinversion_enabled) return;

            gl.showLoading = true;
            const botInfo = BOTS_DATA_FORM_FACTORY_PREPARE(dataForm);
            delete botInfo.notifications;

            let result;

            try {
                result = await botsService.getBotsAutoInvList(botInfo);
            } catch {
                message.error(t('errorMessage'));
            }

            messageInfo.type = !result?.data.status ? 'error' : 'success';
            messageInfo.msg = result.data.message;

            if (!result?.data.status) {
                // show messages
                // message['error'](data.data.message);
                
            } else {
                botsAutoInvRefs.value = result.data;
            }

            gl.showLoading = false;
        };

        watch(() => dataForm.algo_autoinversion_enabled, v => {
            v
                ? syncBotsAutoInvList()
                : hideCollapse();
        });

        onMounted(() => {
            syncBotsAutoInvList();
        });

        return {
            gl,
            bots,
            refs,
            show,
            collapse,
            dataForm,
            messageInfo,
            botsAutoInvListOptions,
            t,
        };
    },
};
</script>