<template>
<div class="flex items-center cursor-pointer" @click="show = !show">
    <div class="text-md text-gray-600 dark:text-white/75 font-semibold">{{ bots.localization['bot_start_filters_title'] }}</div>
    <n-icon class="text-sm ml-2 transition transform text-base" :class="show ? 'rotate-90' : ''">
        <IosArrowRtl24Filled />
    </n-icon>
    <n-button
        v-if="dataForm.start_filters_enabled"
        strong
        secondary
        size="small"
        class="rounded-md ml-4"
        :disabled="!dataForm.start_filters.length"
        @click.prevent.stop="showCreateNewPreset = true">
        {{ bots.localization?.bot_settings_filters_save_button || 'Сохранить шаблон фильтров' }}
    </n-button>
</div>
<n-collapse-transition :show="show">
    <div class="mt-4">
        <section class="flex justify-between items-center">
            <rb-checkbox
                :label="'- ' + bots.localization['bot_start_filters_enable_checkbox']"
                :helpWarning="dataForm.start_filters_enabled ? bots.localization['bots_bot_start_filters'] : null"
                v-model:checked="dataForm.start_filters_enabled" />

            <div v-if="dataForm.start_filters_enabled" class="flex flex-grow justify-end">
                <rb-select
                    label-position="left"
                    :label="(bots.localization?.filters_template_title || 'Шаблоны фильтров') + ':'"
                    :options="filterPresetsOptions"
                    v-model:value="filterId"
                    @update:value="changeFilterPresets" />
                <router-link class="ml-2" :to="{ name: 'tools.presets', params: { tab: 'start-filters-presets' } }" >
                    <n-button
                        secondary
                        class="rounded-md font-semibold w-full">
                        {{ bots.localization?.bot_template_management_label || 'Управление шаблонами' }}
                    </n-button>
                </router-link>
            </div>
        </section>
        <div v-if="dataForm.start_filters_enabled" class="mt-4">
            <div
                v-for="(filter, i) in dataForm.start_filters"
                :key="i+'_'+filter.id+'_'+filter.id_op"
                class="mb-4">
                <n-card
                    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg relative"
                    :segmented="{
                        content: true,
                        footer: 'soft'
                    }">
                    <n-icon
                        class="text-red-500 text-2xl ml-4 cursor-pointer absolute top-2 right-2"
                        @click="onDeleteStartFilter(i)">
                        <DeleteDismiss24Regular />
                    </n-icon>
                    
                    <div class="flex mt-4">
                        <rb-select
                            class="flex-grow pr-1"
                            :label="bots.localization['bot_start_filters_filter_number'] + `${i + 1}:`"
                            :options="startFiltersOptions"
                            v-model:value="dataForm.start_filters[i].id"
                            @update:value="updateStartFilters({ i, key: 'id',  value: $event || -1 })" />
                        <rb-select
                            class="w-2/12 px-1"
                            :label="bots.localization['bot_start_filters_filter_operation'] + `${i + 1}:`"
                            :options="startFiltersOptOptions"
                            :value="dataForm.start_filters[i].id_op"
                            @update:value="updateStartFilters({ i, key: 'id_op',  value: $event || -1 })" />
                        <rb-input
                            class="w-2/12 px-1"
                            :label="bots.localization['bot_start_filters_filter_value'] + `${i + 1}:`"
                            :value="dataForm.start_filters[i].value"
                            @update:value="updateStartFilters({ i, key: 'value',  value: $event })" />
                        <rb-input
                            class="w-2/12"
                            :class="startFilterHasPair(filter.id, i) ? 'px-1' : 'pl-1'"
                            :label="bots.localization['bot_start_filters_params_name']"
                            :value="dataForm.start_filters[i].data"
                            @update:value="updateStartFilters({ i, key: 'data',  value: $event })" />
                        <rb-pair-select
                            v-if="startFilterHasPair(filter.id, i)"
                            class="w-2/12 pl-1"
                            :options="pairsRefs"
                            :label="bots.localization['pair_f']"
                            :value="dataForm.start_filters[i].pair"
                            :placeholder="bots.localization['bots/start_filters/current_pair/label']"
                            @update:value="updateStartFilters({ i, key: 'pair',  value: $event })" />
                    </div>
                </n-card>
            </div>
            <div class="mb-4 flex justify-start">
                <n-button
                    strong
                    secondary
                    class="rounded-md"
                    @click="onAddNewStartFilter">
                    + {{ bots.localization?.add_new_start_filter || 'Add new start filter' }}
                </n-button>
            </div>
        </div>
    </div>
</n-collapse-transition>
<base-modal-desktop
    :label="bots.localization?.bot_settings_filters_save_modal_title || 'Новый шаблон фильтров'"
    v-model:show="showCreateNewPreset">
    <template #default>
        <rb-input
            class="mt-4"
            :label="newPresetModel.title.title"
            :status="newPresetModel.title.status"
            :msg="newPresetModel.title.msg"
            :placeholder="newPresetModel.title.placeholder"
            v-model:value="newPresetModel.title.value"
            @update:value="newPresetModel.title.msg = null, newPresetModel.title.status = null" />
        <rb-input
            class="mt-4"
            type="textarea"
            :label="newPresetModel.description.title"
            :status="newPresetModel.description.status"
            :msg="newPresetModel.description.msg"
            :placeholder="newPresetModel.description.placeholder"
            v-model:value="newPresetModel.description.value" />
    </template>
    <template #footer>
        <div class="flex justify-end">
            <n-button
                strong
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                :disabled="loading"
                :loading="loading"
                @click="onCreateNewFiltersPreset">
                {{ bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_save_button'] }}
            </n-button>
        </div>
    </template>
</base-modal-desktop>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NCollapseTransition } from 'naive-ui';

// components
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbCheckbox from '@components/rb-checkbox';
import RbPairSelect from '@components/rb-pair-select';

// icons
import { Dismiss16Filled, IosArrowRtl24Filled, DeleteDismiss24Regular } from '@vicons/fluent';

export default {
    name: 'bot-start-filters',
    props: {
        pairsRefs: {
            type: Array,
            required: true,
        },
    },
    components: {
        NIcon,
        NCard,
        NAlert,
        RbInput,
        NButton,
        NDivider,
        RbSelect,
        RbCheckbox,
        RbPairSelect,
        Dismiss16Filled,
        IosArrowRtl24Filled,
        NCollapseTransition,
        DeleteDismiss24Regular,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>