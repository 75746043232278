<template>
<section>
    <rb-select
        v-if="!skipPositionMode"
        :help-warning="bots.localization['bots/positionmode/help0']"
        :label="bots.localization['bots/positionmode/label']"
        :options="positionmodeOptions"
        v-model:value="dataForm.settings.positionmode" />
    <n-alert v-if="!skipPositionMode && dataForm.settings.positionmode == 1" class="mt-4 rounded-md" type="warning" size="small">
        <div class="notes text-xs" v-html="bots.localization['bots/positionmode/help1']"></div>
    </n-alert>
    <n-alert v-if="!skipPositionMode && dataForm.settings.positionmode == 2" class="mt-4 rounded-md" type="warning" size="small">
        <div class="notes text-xs" v-html="bots.localization['bots/positionmode/help2']"></div>
    </n-alert>
    <div v-if="dataForm.settings.positionmode == 1" class="mt-4">
        <rb-checkbox
            :nowrap="false"
            :label="'- ' + bots.localization['bots/positionmode/oneposition/label']"
            v-model:checked="dataForm.settings.positionmode_oneposition" />
        <rb-checkbox
            v-if="isPositionmodeReduceVisible"
            :nowrap="false"
            class="mt-2"
            :label="'- ' + bots.localization['bots/positionmode/reduce/label']"
            v-model:checked="dataForm.settings.positionmode_reduce" />
    </div>
</section>
</template>

<script>
import { ref, computed, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';

// components
import RbSelect from '@components/rb-select';
import RbCheckbox from '@components/rb-checkbox';

// naive-ui
import {
    NIcon,
    NAlert,
    NSelect,
    NCheckbox } from 'naive-ui';

// i18n
import { useI18n } from 'vue-i18n';

// icons
import { Warning24Filled } from '@vicons/fluent';

export default {
    name: 'bot-positionmode',
    components: {
        NIcon,
        NAlert,
        NSelect,
        RbSelect,
        NCheckbox,
        RbCheckbox,
        Warning24Filled,
    },
    setup() {
        // store
        const gl = useGl();
        const refs = useRefs();
        const bots = useBots();

        // vars
        const dataForm = bots.dataForm;

        // i18n
        const { t } = useI18n();

        const skipPositionMode = computed(() => !!refs.exchanges.find(({ id }) => id === dataForm.exchange.value)?.skip_position_mode);
        const isPositionmodeReduceVisible = computed(() => refs.exchanges.find(({ id }) => id === dataForm.exchange.value)?.noreduce !== true);

        const positionmodeOptions = computed(() => bots.positionmodes.map(el => ({
            label: el.title,
            value: +el.id,
            ...el,
        })));

        return {
            gl,
            bots,
            dataForm,
            skipPositionMode,
            positionmodeOptions,
            isPositionmodeReduceVisible,
            t,
        };
    },
};
</script>