<template>
<div class="flex items-center cursor-pointer" @click="show = !show">
    <div class="text-md text-gray-600 dark:text-white/75 font-semibold">{{ bots.localization['bots/settings/additional_settings'] }}</div>
    <n-icon class="ml-2 transition transform text-base" :class="show ? 'rotate-90' : ''">
        <IosArrowRtl24Filled />
    </n-icon>
</div>
<n-collapse-transition :show="show">
    <section class="mt-4">
        <rb-select
            multiple
            :defaultValue="false"
            :label="bots.localization['bots/filters/tags/label']"
            :options="botRefsTagsOptions"
            :value="localValue"
            @update:value="updateValue" />

        <rb-input
            class="mt-4"
            type="textarea"
            :label="bots.localization['bots/settings/additional_settings/notes']"
            v-model:value="dataForm.notes" />
    </section>
</n-collapse-transition>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NModal,
    NInput,
    NButton,
    NDivider,
    NPopover,
    NCollapseTransition } from 'naive-ui';

// components
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbCheckbox from '@components/rb-checkbox';
import RbCheckboxGroup from '@components/rb-checkbox-group';

// icons
import { Dismiss16Filled, QuestionCircle20Filled, IosArrowRtl24Filled } from '@vicons/fluent';

export default {
    name: 'bot-notifications',
    components: {
        NIcon,
        NCard,
        NInput,
        NAlert,
        NModal,
        RbInput,
        NButton,
        NPopover,
        NDivider,
        RbSelect,
        RbCheckbox,
        Dismiss16Filled,
        RbCheckboxGroup,
        IosArrowRtl24Filled,
        NCollapseTransition,
        QuestionCircle20Filled,
    },
    setup(props, context) {

        return {
            ...general(...arguments),
        };
    },
};
</script>